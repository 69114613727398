import { getPage } from 'lib/api'
// import getLocation from 'lib/getLocation'
import { useEffect } from 'react'
import Page from 'components/Page'
import groq from 'groq'
import { useRouter } from 'next/router'
import { useDiscountFromURL } from 'context/globalStore'

export default function Home(props) {
  const router = useRouter()
  const { setDiscountFromURL } = useDiscountFromURL()
  useEffect(() => {
    const url = router.asPath
    if (url.indexOf('?') > -1) {
      let query = url.split('?')
      if (query.length > 0) {
        for (let i = 0; i < query.length; i++) {
          if (query[i].indexOf('=') > -1) {
            var keys = query[i].split('=')
            if (keys[0] === 'discount') {
              setDiscountFromURL(keys[1])
              break
            }
          }
        }
      }
    }
    if (url.indexOf('&') > -1) {
      let query = url.split('&')
      if (query.length > 0) {
        for (let i = 0; i < query.length; i++) {
          if (query[i].indexOf('=') > -1) {
            var keys = query[i].split('=')
            if (keys[0] === 'discount') {
              setDiscountFromURL(keys[1])
              break
            }
          }
        }
      }
    }
  }, [])

  return (
    <>
      <h1 className="sr-only">Blueland: Eco-Friendly Cleaning Products</h1>
      <Page {...props} homepageCta={props?.globals?.homepageCta} canonical="/" />
    </>
  )
}

export async function getStaticProps({ preview, previewData }) {
  const props = await getPage(
    (pageQuery) => groq`*[_type == 'globals'][0] {
      ...homepage-> ${pageQuery},
    }`,
    {
      active: preview,
      token: previewData?.token,
    }
  )

  // const countryCode = await getLocation()
  return {
    props: {
      ...props,
      // countryCode
    },
  }
}
